/* eslint-disable react/prop-types */
import Accordion from "@/components/Accordion";
import Block from "@/components/Block";
import Cards from "@/components/Cards";
import Carousel from "@/components/Carousel";
import style from "@/components/ContentBlocks.module.scss";
import EventsCarousel from "@/components/EventsCarousel";
import Heading from "@/components/Heading";
import HorizontalRule from "@/components/HorizontalRule";
import ImageAndText from "@/components/ImageAndText";
import Panel from "@/components/Panel";
import Quotations from "@/components/Quotations";
import SingleImage from "@/components/SingleImage";
import Slideshow from "@/components/Slideshow";
import Spacer from "@/components/Spacer";
import VideoEmbed from "@/components/VideoEmbed";
import clsx from "clsx";
import React from "react";
import BodyText from "./BodyText";

const Component = ({ block }) => {
  switch (block.typeHandle) {
    case "heading": {
      return (
        <Heading
          heading={block.heading}
          standfirst={block.standfirst}
          layout={block.layout}
          alignment={block.alignment}
          headingType={block.headingType}
        />
      );
    }

    case "cards": {
      return (
        <Cards
          cards={block.cards}
          backgroundTheme={block.backgroundTheme || "light"}
          cardSize={block.cardSize || "base"}
          gridSize={block.gridSize || 3}
        />
      );
    }

    case "carousel": {
      return <Carousel cards={block.cards} />;
    }

    case "accordion": {
      return <Accordion items={block.items} />;
    }

    case "panel": {
      const image = block.image?.[0];

      return (
        <Panel
          image={image}
          heading={block.heading}
          caption={block.caption}
          target={block.target}
          textColor={block.textColor}
          imagePosition={block.imagePosition || "left"}
          imageSize={block.imageSize || "base"}
        />
      );
    }

    case "quotations": {
      return <Quotations cells={block.quotations} />;
    }

    case "singleImage": {
      const image = block.image?.[0];

      return (
        <div
          className={clsx("w-full px-6 lg:px-16", {
            "mr-auto lg:max-w-5xl": block.position === "left",
            "ml-auto lg:max-w-5xl": block.position === "right",
            "mx-auto lg:max-w-5xl": block.position === "center",
            "w-full": block.position === "full",
          })}
        >
          <SingleImage image={image} caption={block.caption} />
        </div>
      );
    }

    case "videoEmbed": {
      return (
        <div className={clsx("w-full px-6 md:px-10 lg:px-16", {})}>
          <VideoEmbed videoUrl={block.videoUrl} caption={block.caption} />
        </div>
      );
    }

    case "horizontalRule": {
      return <HorizontalRule />;
    }

    case "imageAndText": {
      const image = block.image[0];

      return (
        <ImageAndText
          image={image}
          imagePosition={block.imagePosition}
          heading={block.heading}
          text={block.text}
          target={block.target}
        />
      );
    }

    case "eventsCarousel": {
      return <EventsCarousel events={block.events} />;
    }

    case "bodyText": {
      return (
        <div
          className={clsx("w-full px-6 lg:px-16", {
            "mr-auto lg:max-w-3xl": block.position === "left",
            "mx-auto lg:max-w-3xl": block.position === "center",
          })}
        >
          <BodyText text={block.text} />
        </div>
      );
    }

    case "spacer": {
      return <Spacer size={block.size} />;
    }

    default:
      return <div>Page component {block.typeHandle}</div>;
  }
};

const ContentBlocks = ({ contentBlocks }) => {
  const className = clsx(style.root, {});

  return (
    <div className={className}>
      {contentBlocks.map((block) => {
        // Get the block's component
        return (
          <div key={block.id} className={style.block}>
            <Block
              spaceAbove={block.spaceAbove}
              spaceBelow={block.spaceBelow}
              backgroundColor={
                block.typeHandle !== "cards" ? block.backgroundColor : null
              }
            >
              <Component block={block} />
            </Block>
          </div>
        );
      })}
    </div>
  );
};

export default ContentBlocks;
