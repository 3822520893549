import BodyText from "@/components/BodyText";
import Button from "@/components/Button";
import Target, { type TargetProps } from "@/components/Target";
import { widont } from "@/lib/helpers";
import clsx from "clsx";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const Panel = ({
  heading,
  image,
  caption,
  textColor,
  target,
  imagePosition = "left",
  imageSize = "base", // base, lg, full
}: {
  heading: string;
  image: {
    url: string;
    width?: number;
    height?: number;
  };
  caption: string;
  textColor: string;
  target: TargetProps;
  imagePosition: "left" | "right";
  imageSize: "base" | "lg" | "full";
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  // Local state for dev mode toggles
  const [localImagePosition, setLocalImagePosition] = useState(imagePosition);
  const [localImageSize, setLocalImageSize] = useState(imageSize);
  const [isDev, setIsDev] = useState(false);

  // Check if we're in development mode
  useEffect(() => {
    setIsDev(process.env.NODE_ENV === "development");

    // Use the props as initial values for the local state
    setLocalImagePosition(imagePosition);
    setLocalImageSize(imageSize);
  }, [imagePosition, imageSize]);

  // Dev-only controls component
  const DevControls = () => {
    if (!isDev) return null;

    return (
      <div className="absolute bottom-4 right-4 z-50 bg-white shadow-md rounded-md p-3 text-sm opacity-70 hover:opacity-100 transition-opacity">
        <div className="flex flex-col space-y-2">
          <div>
            <label className="block mb-1 font-medium text-gray-700">
              Position:
            </label>
            <div className="flex space-x-2">
              <button
                onClick={() => setLocalImagePosition("left")}
                className={clsx("px-2 py-1 rounded text-xs", {
                  "bg-blue-500 text-white": localImagePosition === "left",
                  "bg-gray-200": localImagePosition !== "left",
                })}
              >
                Left
              </button>
              <button
                onClick={() => setLocalImagePosition("right")}
                className={clsx("px-2 py-1 rounded text-xs", {
                  "bg-blue-500 text-white": localImagePosition === "right",
                  "bg-gray-200": localImagePosition !== "right",
                })}
              >
                Right
              </button>
            </div>
          </div>

          <div>
            <label className="block mb-1 font-medium text-gray-700">
              Size:
            </label>
            <div className="flex space-x-2">
              <button
                onClick={() => setLocalImageSize("base")}
                className={clsx("px-2 py-1 rounded text-xs", {
                  "bg-blue-500 text-white": localImageSize === "base",
                  "bg-gray-200": localImageSize !== "base",
                })}
              >
                Base
              </button>
              <button
                onClick={() => setLocalImageSize("lg")}
                className={clsx("px-2 py-1 rounded text-xs", {
                  "bg-blue-500 text-white": localImageSize === "lg",
                  "bg-gray-200": localImageSize !== "lg",
                })}
              >
                Large
              </button>
              <button
                onClick={() => setLocalImageSize("full")}
                className={clsx("px-2 py-1 rounded text-xs", {
                  "bg-blue-500 text-white": localImageSize === "full",
                  "bg-gray-200": localImageSize !== "full",
                })}
              >
                Full
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // For full image mode, we use a different layout
  if (localImageSize === "full") {
    return (
      <div className={clsx("Panel", "relative")} ref={ref}>
        <DevControls />
        <div
          className={clsx(
            "Panel__content",
            "relative overflow-hidden opacity-0 transition-opacity duration-700 flex flex-col gap-y-2",
            {
              "opacity-100": inView,
            }
          )}
        >
          {/* Full-sized background image */}
          {!!image && (
            <div className="Panel__imageContainer w-full relative lg:min-h-[600px] lg:max-h-[90vh]">
              <div className="relative aspect-[4/3] lg:aspect-auto lg:absolute lg:inset-0">
                <Image
                  className="Panel__image object-cover w-full h-full"
                  src={image.url}
                  fill
                  sizes="100vw"
                  quality={90}
                  alt={heading || ""}
                  priority
                />
              </div>

              {/* Text overlay - vertical alignment changes based on screen size */}
              <div className="container lg:absolute lg:inset-0 mx-auto lg:h-full">
                <div
                  className={clsx(
                    "",
                    /* On mobile: flex column with bottom alignment */
                    "flex flex-col justify-end",
                    /* On desktop: flex row with top alignment and padding */
                    "md:flex-row md:items-start lg:py-8",
                    {
                      "md:justify-start": localImagePosition === "left",
                      "md:justify-end": localImagePosition === "right",
                    }
                  )}
                >
                  <div
                    className={clsx(
                      "Panel__text",
                      /* Maintain padding and width */
                      "relative z-10 py-6 md:py-8 w-full lg:w-1/2 2xl:w-[45%] lg:px-8 2xl:px-16 lg:py-8 2xl:py-16 flex flex-col gap-y-4",
                      /* Rounded top corners on mobile, all corners on desktop */
                      "rounded-t-lg md:rounded-lg backdrop-blur-sm bg-white/95",
                      /* No margin on mobile, only horizontal margin on desktop */
                      "mx-0 mb-0 mt-0"
                    )}
                  >
                    <Target
                      className={clsx(
                        "Panel__heading",
                        "inline-block font-medium",
                        "text-3xl md:text-3xl xl:text-4xl"
                      )}
                      target={target}
                      style={{ color: textColor }}
                    >
                      {heading}
                    </Target>
                    <div
                      className={clsx("Panel__caption")}
                      style={{ color: textColor }}
                    >
                      <BodyText text={caption} size="large" />
                    </div>
                    {!!target.url && (
                      <div>
                        <Target
                          className={clsx("Panel__button", "inline-block pt-2")}
                          target={target}
                        >
                          <Button
                            label={target.text || ""}
                            textColor={textColor}
                          />
                        </Target>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  // Original layout for base and lg modes
  return (
    <div className={clsx("Panel", "")} ref={ref}>
      <DevControls />
      <div
        className={clsx(
          "Panel__content",
          "mx-auto flex max-w-screen-2xl flex-col overflow-hidden opacity-0 transition-opacity duration-700 ",
          {
            "md:flex-row md:items-center": localImagePosition === "left",
            "md:flex-row-reverse md:items-center":
              localImagePosition === "right",
            "opacity-100": inView,
          }
        )}
      >
        {!!image && (
          <div
            className={clsx(
              "Panel__imageContainer",
              "w-full px-6 md:px-10 lg:px-16 lg:py-16",
              {
                "md:w-1/2": localImageSize === "base",
                "md:w-3/5": localImageSize === "lg",
              }
            )}
          >
            <div>
              <Target
                className={clsx(
                  "Panel__imageWrapper",
                  "overflow-hidden rounded-lg"
                )}
                target={target}
              >
                <Image
                  className={clsx("Panel__image")}
                  src={image.url}
                  width={image.width || 1200}
                  height={image.height || 800}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 40vw"
                  alt={heading || ""}
                />
              </Target>
            </div>
          </div>
        )}

        <div
          className={clsx(
            "Panel__text",
            "px-6 py-8 lg:px-16 lg:py-16 flex flex-col",
            {
              "lg:w-1/2 gap-y-4": localImageSize === "base",
              "lg:w-2/5 gap-y-2": localImageSize === "lg",
            }
          )}
          style={{ color: textColor }}
        >
          <Target
            className={clsx(
              "Panel__heading",
              "inline-block font-medium text-balance",
              {
                "text-3xl md:text-3xl xl:text-4xl": localImageSize === "lg",
                "text-2xl md:text-4xl xl:text-5xl": localImageSize === "base",
              }
            )}
            target={target}
            style={{ color: textColor }}
          >
            {heading}
          </Target>
          <div
            className={clsx("Panel__caption", "font-light", "", {
              "text-xl lg:text-xl xl:text-2xl": localImageSize === "lg",
              "text-xl lg:text-2xl xl:text-3xl": localImageSize === "base",
            })}
            style={{ color: textColor }}
            dangerouslySetInnerHTML={{ __html: caption }}
          />
          {!!target.url && (
            <div className="pt-4 lg:pt-4">
              <Target
                className={clsx("Panel__button", "inline-block")}
                target={target}
              >
                <Button label={target.text || ""} textColor={textColor} />
              </Target>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Panel;
