import clsx from "clsx";
import React from "react";
import ReactHtmlParser from "react-html-parser";

const BodyText = ({ text, size = "base" }) => {
  return (
    <div className={clsx("BodyText", "@container")}>
      <div
        className={clsx(
          "BodyText__content",
          "prose w-full text-gray-600 prose-a:decoration-gray-400/50 prose-a:decoration-1 prose-a:underline-offset-4 hover:prose-a:decoration-gray-700/50 prose-strong:font-medium font-serif",
          {
            "prose-xl @md:lg:prose-xl": size === "large" || size === "lg",
            "prose-lg @md:prose-xl": size === "normal" || size === "base",
            "prose @md:prose-lg": size === "small" || size === "sm",
          }
        )}
      >
        {ReactHtmlParser(text)}
      </div>
    </div>
  );
};

export default BodyText;
